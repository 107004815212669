.mk_l hr {
  margin-top: 10px;
  margin-bottom: 10px;
  border: 0;
  border-top: 1px solid #282119;
  color: #222;
}

.mk_d {
  a {
    font-size: 14px;
  }
}

.mk_l * {
  font-size: 14px;
  line-height: 1.6em;
}

strong,
em {
  font-size: 14px !important;
}

.mk_l p {
  margin-bottom: 20px;
  font-family: e-Ukraine, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #282119;

  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
}

h1 {
  font-size: 24px !important;
}

.mk_l h1 {
  font-size: 24px !important;
  font-weight: bold;
  margin: 0 0 25px 0;
}

.mk_l h2 {
  font-size: 25px;
  font-weight: bold;
  margin: 0 0 25px 0;
}

.mk_l h3 {
  font-size: 23px;
  font-weight: bold;
  margin: 0 0 25px 0;
}

.mk_l h4 {
  font-size: 21px;
  font-weight: bold;
  margin: 0 0 25px 0;
}

.mk_l h5 {
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 25px 0;
}

.mk_l h6 {
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 25px 0;
}

.mk_l blockquote {
  margin-bottom: 10px;
  padding: 10px 20px;
  border-left: 5px solid #282119;
}

.mk_l pre {
  padding: 12px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  margin-bottom: 10px;
}

.mk_l li {
  padding: 4px 0;

  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #282119;

  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
}

.mk_l ul {
  margin: 10px 0;
  padding-left: 30px;
}

.mk_l ol {
  margin: 10px 0;
  padding-left: 30px;
}

.mk_l ul li {
  list-style-type: disc;
  font-family: e-Ukraine, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #282119;

  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
}

.mk_l ol li {
  list-style-type: decimal;
  font-family: e-Ukraine, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #282119;

  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
}

.mk_l a {
  color: #924e27;
  border-bottom: 1px solid#924E27;
  text-decoration: none;
  transition: color 0.5s, border-color 0.5s;
}

.mk_l a:hover {
  opacity: 0.7;
  color: #924e27;
  border-color: transparent;
}

.mk_l img {
  width: auto;
  height: auto;
  max-width: 100%;
}

.mk_d hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #282119;
}

.mk_d * {
  font-size: 16px;
  line-height: 1.6em;
  color: #282119;
  text-align: justify;
}

.mk_d p {
  margin-bottom: 15px;

  font-family: e-Ukraine, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #282119;

  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
}

.mk_d h1 {
  font-size: 27px;
  font-weight: bold;
  margin-bottom: 15px;
}

.mk_d h2 {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 15px;
}

.mk_d h3 {
  font-size: 23px;
  font-weight: bold;
}

.mk_d h4 {
  font-size: 21px;
  font-weight: bold;
}

.mk_d h5 {
  font-size: 18px;
  font-weight: bold;
}

.mk_d h6 {
  font-size: 16px;
  font-weight: bold;
}

.mk_d blockquote {
  margin: 15px 0;
  padding: 15px 20px;
  border-left: 5px solid #282119;
}

.mk_d pre {
  padding: 12px;
  background-color: rgba(226, 10, 10, 0.1);
  border-radius: 5px;
  margin: 15px 0;
}

.mk_d ul {
  margin: 15px 0;
  padding-left: 30px;
}

.mk_d ol {
  margin: 8px 0 15px 0;
  padding-left: 30px;
}

.mk_d ul li {
  font-family: e-Ukraine, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #282119;

  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
}

.mk_d ol li {
  list-style-type: decimal;

  font-family: e-Ukraine, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #282119;

  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
}

.mk_d a {
  color: #924e27;
  border-bottom: 1px solid#924E27;
  text-decoration: none;
  transition: color 0.5s, border-color 0.5s;
}

.mk_d a:hover {
  opacity: 0.7;
  color: #924e27;
  border-color: transparent;
}

.mk_d img {
  width: auto;
  height: auto;
  margin: 15px 0;
  max-width: 100%;
}
