@font-face {
  font-family: 'Raleway';
  src: url('../font/Raleway-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../font/Raleway-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('../font/Raleway-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../font/Raleway-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('../font/Raleway-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

/* @font-face {
  font-family: 'Raleway';
  src: url('../font/Raleway-ExtraBoldItalic.ttf') format('truetype');
  font-weight: 800;
  font-style: italic;
} */

@font-face {
  font-family: 'Raleway';
  src: url('../font/Raleway-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../font/Raleway-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('../font/Raleway-Italic-VariableFont_wght.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('../font/Raleway-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('../font/Raleway-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../font/Raleway-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('../font/Raleway-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../font/Raleway-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('../font/Raleway-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../font/Raleway-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../font/Raleway-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('../font/Raleway-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Raleway';
  src: url('../font/Raleway-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Raleway';
  src: url('../font/Raleway-VariableFont_wght.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

html {
  background-color: #faf9f2;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

* {
  font-family: 'Raleway';
}

main::-webkit-scrollbar {
  display: none !important;
}

/* Скрываем scrollbar для IE, Edge и Firefox */
main {
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;
}

::-webkit-scrollbar {
  display: none !important;
}

h2 {
  font-size: 20px;
}

p {
  margin: 0;
  padding: 0;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  font-weight: inherit;
  text-align: inherit;
}
